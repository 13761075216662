@import-normalize;

* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: whitesmoke;
  line-height: normal;
}

/*------------------------------------*\
\*------------------------------------*/
.header__container,
.filter,
.results {
  display: flex;
  flex-direction: column;
  width: 85vw;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter {
  max-width: 400px;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  padding: 0.5em 1.5em;
  padding-bottom: 0;
  border-radius: 4px;
  -moz-box-shadow: 0 0 7px #777;
  -webkit-box-shadow: 0 0 7px #777;
  box-shadow: 0 0 7px #777;
}

.results {
  max-width: 800px;
}

/*------------------------------------*\
#HEADER
\*------------------------------------*/
.header {
  padding: 1.5rem 0;
  background-color: #040f2f;
}

.header__title,
.header__subtitle {
  color: white;
  line-height: normal;
}

.header__title {
  font-size: 2rem;
  letter-spacing: 1px;
}

.header__subtitle {
  font-size: 1rem;
  letter-spacing: 0.5px;
}

/*------------------------------------*\
  #FILTER
\*------------------------------------*/
.filter__dates-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.filter__input-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.filter__input-container label {
  position: absolute;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #566176;
  font-size: 1rem;
  line-height: 1;
  pointer-events: none;
}

.filter__input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #0a2c8f;
}

.filter__input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.filter__input-container input,
.filter__input-container select {
  height: 60px;
  /* padding: 24px 16px 4px 16px; */
  padding: 24px 16px 0 0;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: none;
  border-bottom: 1px solid #566176;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 1rem;
  line-height: 1;
}

/*------------------------------------*\
  #RESULTS
\*------------------------------------*/
.ato {
  padding: 1.5em 0;
  border-bottom: 1px solid slategrey;
  overflow-wrap: break-word;
}

.ato:last-child {
  border-bottom: none;
}

.ato__title {
  color: #1f93c5;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
}

.ato__title:hover {
  text-decoration: underline;
}

.ato__entidades {
  padding: 0.25em 0;
  color: slategrey;
  font-weight: 600;
}

.ato__summary {
  color: #222222;
  font-weight: 600;
}

.ato__montante {
  display: inline-block;
  position: relative;
  margin-top: 0.25em;
  border-bottom: 1px dotted #222222;
  color: #222222;
  font-weight: 600;
}

.ato__tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: max-content;
  max-width: 80vw;
  margin-top: 0.5em;
  padding: 0 0.375rem;
  transition: opacity 0.2s;
  border: 1px solid gray;
  border-radius: 6px;
  opacity: 0;
  background-color: whitesmoke;
  color: #222222;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  /* filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px); */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px; */
}

.ato__montante .ato__tooltip::after {
  content: " ";
  position: absolute;
  bottom: 101%;
  left: 12%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.ato__montante:hover .ato__tooltip {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 350px) {
  .ato__tooltip {
    top: -5px;
    left: 105%;
    margin-left: 5px;
  }

  .ato__montante .ato__tooltip::after {
    visibility: hidden;
    /* left arrow */
    /* top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    margin-top: -5px;
    margin-left: auto;
    border-color: transparent gray transparent transparent; */
  }
}

/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/
button {
  border: none;
  background-color: #040f2f;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 8px 16px, rgba(0, 0, 0, 0.23) 0px 5px 5px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

button:hover,
button:focus {
  background-color: #363f59;
}

button:active {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.filter-btn {
  width: 100%;
  margin: 0.75em auto;
  padding: 0.5em 0;
  border-radius: 4px;
  letter-spacing: 1px;
}

.moreResults-btn {
  width: 66vw;
  max-width: 252px;
  margin: 3em auto;
  padding: 1em 1em;
  border-radius: 4px;
  letter-spacing: 0.5px;
}

/*------------------------------------*\
\*------------------------------------*/
.spinner-div {
  margin: 3em auto;
}

.noResults {
  margin: 5em auto;
  font-size: 1.125rem;
  font-weight: 500;
}

/*------------------------------------*\
\*------------------------------------*/
@media (min-width: 465px) {
  .filter__dates-container {
    flex-direction: row;
  }
  .filter__input-container {
    width: auto;
  }
}

@media (min-width: 550px) {
  html {
    font-size: 1.0625rem;
  }
}

@media (min-width: 925px) and (min-height: 500px) {
  .header__container {
    width: 250px;
    margin: 0;
    text-align: center;
  }

  .main {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .filter {
    position: sticky;
    top: 0;
    width: 250px;
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }

  .results {
    margin: 0 auto;
    padding: 0 2rem;
  }

  .filter__dates-container {
    flex-direction: column;
  }
}

@media (min-width: 1025px) {
  .filter {
    width: 300px;
  }
  .header__container {
    width: 300px;
  }
}

@media (min-width: 1300px) {
  .filter {
    width: 400px;
  }
  .header__container {
    width: 400px;
  }

  .filter__dates-container {
    flex-direction: row;
  }
}
